import React, { useContext, useEffect, useState } from 'react'
import './contactUs.scss'
import { colors } from '../../constants/colors'
import MainTextField from '../../components/textfields/mainTextField/MainTextField'
import MainTextArea from '../../components/textfields/mainTextArea/MainTextArea'
import MainDropdown from '../../components/dropdown/mainDropdown/MainDropdown'
import { DataStoreContext } from "../../context/DataStoreContext";
import MainButton from '../../components/buttons/mainButton/MainButton'
import { regex } from '../../constants/regex'
import { LoaderContext } from '../../context/LoaderContext'
import { useNotification } from '../../context/NotificationContext'
import { sendContactRequest } from '../../webservice/webservice'
import Footer from '../../components/footer/Footer'
import HeaderAbout from '../../components/header/HeaderAbout'
import { contact, contactDes, us } from '../../constants/data'

function ContactUs ()
{
    const { countryCodes } = useContext( DataStoreContext )
    const countryCodeOptions = countryCodes.map( countryCode => ( {
        label: `+${ countryCode.countryCode } ${ countryCode.countryName }`,
        value: countryCode.countryCode
    } ) )
    const getDefaultCountryCode = countryCodes.filter( countryCode => countryCode.countryAlpha3Code === 'IND' ).map( countryCode => ( {
        label: `+${ countryCode.countryCode } ${ countryCode.countryName }`,
        value: countryCode.countryCode
    } ) )[0]
    const [ contactDetails, setContactDetails ] = useState( {
        name: '',
        email: '',
        countryCode: null,
        phone: '',
        message: ''
    } )

    const { showLoader, hideLoader } = useContext( LoaderContext );
    const notify = useNotification();

    const isEmailValid = () =>
    {
        if ( contactDetails.email === "" || regex.mail.test( contactDetails.email ) )
        {
            return true
        }
        else
        {
            return false
        }
    }

    const isFormValid = () =>
    {
        if (
            contactDetails.name.trim() !== "" &&
            contactDetails.email.trim() !== "" &&
            isEmailValid() &&
            contactDetails.phone.trim() !== "" &&
            contactDetails.countryCode &&
            contactDetails.message.trim() !== ""
        )
        {
            return true
        }
        else
        {
            return false
        }
    }

    const onSendBtnPressed = () =>
    {
        showLoader();
        sendContactRequest(
            contactDetails.name.trim(),
            contactDetails.email.trim(),
            contactDetails.phone.trim(),
            contactDetails.countryCode.value,
            contactDetails.message.trim()
        )
            .then( message =>
            {
                setContactDetails( {
                    name: '',
                    email: '',
                    countryCode: null,
                    phone: '',
                    message: ''
                } )
                hideLoader();
                notify( {
                    type: "SUCCESS",
                    message: message,
                    title: "Success!!!",
                } )
            } )
            .catch( error =>
            {
                hideLoader();
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!",
                } );
            } )

    }

    useEffect(() => {
      if(countryCodes.length > 0){
        setContactDetails({
            ...contactDetails,
            countryCode : getDefaultCountryCode
        })
      }
    }, [countryCodes])
    
    return (
        <div className='contactUs'>
            <HeaderAbout/>
            <div className='titleArea'>
                <div className='title'><span style={ { color: colors.primaryColor } }>{contact}</span> {us}</div>
                <div className='description'>{contactDes}</div>
            </div>
            <div className='container' style={{}}>
            <div className='contact-us-background'>
                <ul className="circles">
                        <li ></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                </ul>
                <div className="fog-effect"/>
            </div>
            <div className='mainSection'>
                <div className='field'>
                    <MainTextField type='text' required title='Name / Organization' placeholder='Name' value={ contactDetails.name } onChange={ ( e ) => { setContactDetails( { ...contactDetails, name: e.target.value } ) } } />
                </div>
                <div className='field'>
                    <MainTextField type='text' required  title='Email' placeholder='Email' value={ contactDetails.email } onChange={ ( e ) => { setContactDetails( { ...contactDetails, email: e.target.value } ) } } errorText={ !isEmailValid() ? 'Invalid email.' : '' } />
                </div>
                <div className='phoneGroup'>
                    <div className='countryCodeField'>
                        <MainDropdown
                            required 
                            title={ 'Country Code' }
                            options={ countryCodeOptions }
                            isClearable
                            value={ contactDetails.countryCode }
                            onChange={ ( selectedOption ) => { setContactDetails( { ...contactDetails, countryCode: selectedOption } ) } }
                            placeholder={ 'Country Code' }
                        />
                    </div>
                    <div className='phoneField'>
                        <MainTextField type='number' required title='Phone' placeholder='Phone' min='0' value={ contactDetails.phone } onChange={ ( e ) => { setContactDetails( { ...contactDetails, phone: e.target.value } ) } } />
                    </div>
                </div>
                <div className='field'>
                    <MainTextArea type='text' required title='Message' placeholder='Message' rows="4" maxLength={ 500 } value={ contactDetails.message } onChange={ ( e ) => { setContactDetails( { ...contactDetails, message: e.target.value } ) } } />
                </div>
                <div className='button'>
                    <MainButton
                        text={ 'Send' }
                        filled
                        disabled={ !isFormValid() }
                        hoverColor={ colors.blackColor }
                        textColor={ colors.whiteColor }
                        onClick={ onSendBtnPressed }
                    />
                </div>
            </div>
            </div>
            
            <Footer/>
        </div>
    )
}

export default ContactUs