import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './styles/global.scss'
import Home from "./pages/home/Home"
import ContactUs from "./pages/contactUs/ContactUs";
import About from "./pages/about/About";
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';

function App() {
  const router = createBrowserRouter( [ 
    {
      path: "/",
      element: <Home />
    },
    {
      path: "/contact-us",
      element: <ContactUs />
    },
    {
      path: "/about",
      element: <About />
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />
    },
   ] )
  return (
    <RouterProvider router={router} />
  )
}

export default App;
