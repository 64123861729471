import React, { createContext, useState, useEffect, useContext } from 'react'
import { LoaderContext } from './LoaderContext';
import { useNotification } from './NotificationContext';
import { getCountryCodesRequest } from '../webservice/webservice';



export const DataStoreContext = createContext();

export default function DataStoreContextProvider ( { children } )
{
    const [ countryCodes, setCountryCodes ] = useState( [] );
    const { showLoader, hideLoader } = useContext( LoaderContext )
    const notify = useNotification();
    const intializeData = () =>
    {
        showLoader();
        Promise.all( [
            getCountryCodesRequest()
        ] )
            .then( values =>
            {
                hideLoader();
                setCountryCodes( values[ 0 ] )
            } )
            .catch( error =>
            {
                hideLoader();
                notify( {
                    type: "ERROR",
                    message: error.message ? error.message : error,
                    title: error.title ? error.title : "Error!!!"
                } )
            } )

    }

    
    useEffect( () =>
    {
        intializeData()
    }, [] )

    return (
        <DataStoreContext.Provider value={ {countryCodes} }>
            { children }
        </DataStoreContext.Provider>
    )
}
