import React, { useState } from "react";
import "./header.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MainIconButton from "../buttons/mainIconButton/MainIconButton";
import { Drawer } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import logoForHeader from '../../images/yscircleslogowiththinfont.png'

export default function HeaderAbout() {
  const { width } = useWindowDimensions();
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <div className="header">
      <div className="left">
        <img
          className="logo"
          src={logoForHeader}
          alt="logo"
          onClick={ () => { navigate( '/' ) } }
        />
      </div>
      {width > 900 ? (
        <div className="right">
          <div className="navigate">
            <NavLink to={'/'} className="navigationLink">Home</NavLink>
            <NavLink to={ '/about'} className="navigationLink">About</NavLink>
            <NavLink to={'/contact-us'} className="navigationLink">Contact Us</NavLink>
          </div>
        </div>
      ) : (
        <div className="right">
          <MainIconButton iconName={"menu"} onClick={toggleDrawer} />
          <Drawer anchor={"right"} open={openDrawer} onClose={toggleDrawer}>
            <div className="drawer">
              <div className="navButtons">
                <NavLink to={'/'} className="navLink">Home</NavLink>
                <NavLink to={'/about'} className="navLink">About</NavLink>
                <NavLink to={'/contact-us'} className="navLink">Contact Us</NavLink>
              </div>
            </div>
          </Drawer>
        </div>
      )}
    </div>
  );
}
