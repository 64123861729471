import React from 'react'
import spinner from "../../images/spinner.gif";
import './loader.scss';

export default function Loader ()
{
    return (
        <div className="loader">
            <img src={ spinner } alt="loading" />
        </div>
    )
}