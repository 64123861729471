import React from 'react'
import './footer.scss'
import { NavLink } from 'react-router-dom'
import logoCompleteWhite from '../../images/YsTechWhiteLogoText.png'

export default function Footer() {
  return (
    <div className='footer'>
        <div className='logoArea'>
            <img className='logo' src={ logoCompleteWhite } alt='logo' />
        </div>
        <div className='sectionsArea'>
            <div className='section'>
            <div className='title'>
                        Site Pages
                    </div>
                <NavLink to={ '/about' } className={ 'navLink' } >
                        About Us
                    </NavLink>      
                <NavLink to={ '/contact-us' } className={ 'navLink' } >
                     Contact Us
                    </NavLink>

               
        </div>
        <div className='section'>
                    <div className='title'>
                        Legal Information
                    </div>
                    <NavLink to={ '/privacy-policy' } className={ 'navLink' } >
                        Privacy Policy
                    </NavLink>
                </div>
        
        </div>
      
    </div>
  )
}





