import React from "react";
import "./about.scss";
import { colors } from "../../constants/colors";
import MainButton from "../../components/buttons/mainButton/MainButton";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import HeaderAbout from "../../components/header/HeaderAbout";
import logoGif from "../../images/Yslogoanimation3d.gif";

import {
  about,
  aboutuswhoweare,
  join,
  joinDesc,
  mission1,
  missions,
  ourApproch,
  ourApprochDes,
  partnership,
  partnershipDesc,
  us,
  welcomeText,
  whatWeDo,
  whatWeDo1title,
  whatWeDo1titleDescription,
  whatWeDo2title,
  whatWeDo2titleDescription,
  whatWeDo3title,
  whatWeDo3titleDescription,
  whatWeDoSummary,
  whyChoose,
  whyChoose1Desc,
  whyChoose1Title,
  whyChoose2Desc,
  whyChoose2Title,
  whyChoose3Desc,
  whyChoose3Title,
  whyChoose4Desc,
  whyChoose4Title,
} from "../../constants/data";
import MainIconButton from "../../components/buttons/mainIconButton/MainIconButton";
import { useNotification } from "../../context/NotificationContext";
import { address, locationUrl, mail, phoneNumber } from "../../config/config";
function About() {
  const navigate = useNavigate();
  const notify = useNotification();
  return (
    <div className="about">
      <HeaderAbout />
      <div className="titleArea">
        <div className="title">
          <span style={{ color: colors.primaryColor }}>{about}</span> {us}
        </div>
        <div className="buttons">
          <div
            className="contactDetails"
            onClick={() => {
              window.open(locationUrl, "_blank", "noopener,noreferrer");
            }}
          >
            <div className="details">
              <MainIconButton iconName={"place"} placement = 'top' title="location" />
              <div className="content">{address}</div>
            </div>
          </div>
          <div
            className="contactDetails"
            onClick={() => {
              navigator.clipboard.writeText(phoneNumber).then(() => {
                notify({
                  type: "SUCCESS",
                  message: "Number copied to clipboard",
                  title: "Success!!!",
                });
              });
            }}
          >
            <div className="details">
              <MainIconButton iconName={"phone"} placement = 'top' title="phone" />
              <div className="content">{phoneNumber}</div>
            </div>
          </div>
          <div
            className="contactDetails"
            onClick={() => {
              navigator.clipboard.writeText(mail).then(() => {
                notify({
                  type: "SUCCESS",
                  message: "Mail copied to clipboard",
                  title: "Success!!!",
                });
              });
            }}
          >
            <div className="details">
              <MainIconButton iconName={"email"} placement = 'top' title="mail" />
              <div className="content">{mail}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mainSection">
        {/* <div className="imgArea">
          <img className="image" src={aboutImg} alt="about" />
        </div> */}
        <div className="textArea">
          <div className="title">{aboutuswhoweare}</div>
          <div className="detail">{welcomeText}</div>
          <div className="title">{missions}</div>
          <div className="detail">{mission1}</div>
          <div className="title">{whatWeDo} </div>
          <div className="detail">{whatWeDoSummary}</div>
          <div className="point">
            <div className="main">
              {whatWeDo1title} {" : "}
              <span className="detail">{whatWeDo1titleDescription}</span>
            </div>
          </div>
          <div className="point">
            <div className="main">
              {whatWeDo2title} {" : "}
              <span className="detail">{whatWeDo2titleDescription}</span>
            </div>
          </div>
          <div className="point">
            <div className="main">
              {whatWeDo3title} {" : "}
              <span className="detail">{whatWeDo3titleDescription}</span>
            </div>
          </div>
          <div className="title">{ourApproch}</div>
          <div className="detail">{ourApprochDes}</div>
          <div className="title">{whyChoose}</div>
          <div className="point">
            <div className="main">
              {whyChoose1Title} {" : "}
              <span className="detail">{whyChoose1Desc}</span>
            </div>
          </div>
          <div className="point">
            <div className="main">
              {whyChoose2Title} {" : "}
              <span className="detail">{whyChoose2Desc}</span>
            </div>
          </div>
          <div className="point">
            <div className="main">
              {whyChoose3Title} {" : "}
              <span className="detail">{whyChoose3Desc}</span>
            </div>
          </div>
          <div className="point">
            <div className="main">
              {whyChoose4Title} {" : "}
              <span className="detail">{whyChoose4Desc}</span>
            </div>
          </div>
          <div className="title">{partnership} </div>
          <div className="detail">{partnershipDesc}</div>
          <div className="title">{join} </div>
          <div className="detail">{joinDesc}</div>
          <div className="button">
            <MainButton
              text={"Contact US"}
              filled
              hoverColor={colors.blackColor}
              textColor={colors.fontColor2}
              onClick={() => {
                navigate("/contact-us");
              }}
            />
          </div>
        </div>
        {/* <ImageSwiper reverse={true} images={images1} />
          <ImageSwiper images={images2} />
          <ImageSwiper reverse={true} images={images3} />
          <ImageSwiper images={images4} />
          <div className="shade left"></div>
          <div className="shade right"></div> */}
        <div className="imageBox">
          <img className="image" src={logoGif} alt="logo gif" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
