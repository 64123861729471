export const colors = {
    primaryColor: '#0A3140',
    secondaryColor: '#69828C',
    backgroundColor: '#E4F1F2',
    accentColor: '#B6F2EC',
    textColor: '#4D6973',
    lightTextColor: '#8FA1A6',
    darkBackgroundColor: '#0D0D0D',
    marginColor: 'rgba(13, 13, 13, 0.1)',
    fontColor2: '#FFFF',
    secondaryBackgroundColor: '#2E195B',
    hoverColor : '#8e44ad',
    badgeTextcolor : '#a991d0',
    badgeBorderColor : '#7f67a7',
    darkTextColor : ' #0D0D0D',
    successColor : 'rgba(50,1,103,0.8576024159663865)',
    errorColor: '#c23616',
    footerItemColor : '#a4b0be',
    buttonColor : '#474787',
    fontColor1 : 'black',
    whiteTransparent50 : 'rgba(255, 255, 255, 0.500)',
    greyColor: 'grey',
    blackColor: 'black',
  };

  //#3c1b54
  