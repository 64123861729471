import config from "../config/config";
import axios from 'axios';
let requestMethod = {
    post: 'POST',
    get: 'GET'
}

let serverUrl = config.serverUrl
let baseUrl = `${ serverUrl }/api/user/`;
let relativeUrl = {
    sendContact: 'mail',
    getCountryCodes: 'country-codes',
}
console.log(baseUrl)

const makeRequest = ( method, relativeUrl, data) =>
{
    return new Promise( function ( resolve, reject )
    {
        var options = {
            'method': method,
            'url': baseUrl + relativeUrl,
            'data': data
        };
        axios( options )
            .then( function ( response )
            {
                resolve( response.data );
            } )
            .catch( function ( err )
            {
                var errorResponse = ( err.response && err.response.data ) ? err.response.data : err;
                if ( errorResponse )
                {
                    reject( errorResponse );
                }
                else
                {
                    console.log( err );
                    reject( {
                        message: 'Some Unknown Error',
                        errorDetail: err
                    } );
                }
            } )
    } )
}

export const sendContactRequest = ( name, email, phoneNumber, countryCode, message ) =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.post, relativeUrl.sendContact,
            { name, email, phoneNumber, countryCode, message })
            .then( function ( response )
            {
                resolve( response.message )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}
export const getCountryCodesRequest = () =>
{
    return new Promise( function ( resolve, reject )
    {
        makeRequest( requestMethod.get, relativeUrl.getCountryCodes,
            null, false )
            .then( function ( response )
            {
                console.log(response.data)
                resolve( response.data )
            } )
            .catch( function ( err )
            {
                reject( err )
            } )
    } )

}
