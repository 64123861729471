import React, { useState } from "react";
import "./header.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MainIconButton from "../buttons/mainIconButton/MainIconButton";
import { Drawer } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import logoForHeader from '../../images/yscircleslogowiththinfont.png'
import {Link} from 'react-scroll';

export default function MainHeader() {
  const { width } = useWindowDimensions();
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <div className="header">
      <div className="left">
        <img
          className="logo"
          src={logoForHeader}
          alt="logo"
          onClick={ () => { navigate( '/' ) } }
        />
      </div>
      {width > 900 ? (
        <div className="right">
          <div className="navigate">
            <NavLink to={'/'} className="navigationLink">Home</NavLink>
            <NavLink to={ '/about'} className="navigationLink">About</NavLink>
            <NavLink to={'/contact-us'} className="navigationLink">Contact Us</NavLink>
            <Link to="project-section" smooth={ true } duration={ 2500 } className="navigationLink">Services</Link>
            <Link to="swiper-section" smooth={true} duration={2500} className="navigationLink">Projects</Link>
          </div>
        </div>
      ) : (
        <div className="right">
          <MainIconButton iconName={"menu"} onClick={toggleDrawer} />
          <Drawer anchor={"right"} open={openDrawer} onClose={toggleDrawer}>
            <div className="drawer">
              <div className="navButtons">
                <NavLink to={'/'} className="navLink">Home</NavLink>
                <NavLink to={'/about'} className="navLink">About</NavLink>
                <NavLink to={'/contact-us'} className="navLink">Contact Us</NavLink>
                  <Link to="project-section" smooth={ true } duration={ 2000 } className="navLink" onClick={ toggleDrawer }>Services</Link>
                  <Link to="swiper-section" smooth={true} duration={2000} className="navLink" onClick={toggleDrawer}>Projects</Link>
              </div>
            </div>
          </Drawer>
        </div>
      )}
    </div>
  );
}
