import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Autoplay,
} from "swiper/modules";
import "./mainSwiper.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import { swiperData } from "../../../constants/data";
import MainIconButton from "../../buttons/mainIconButton/MainIconButton";

export default function MainSwiper({showBackground=true}) {
    const [degree, setDegree] = useState(0)
    const [index, setIndex] = useState(0)
    const [colorOptions,setColorOptions] = useState([])
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + '</span>';
        },
    };
    const rotate = (index) => {
        setDegree(index * 72)
    }
    useEffect (()=>{
        var colorArray=[]
        Object.keys(swiperData).map((key, i)=>{
            colorArray[i]=swiperData[key].bgColor
        })
        setColorOptions(colorArray)
    },[])

    return (
        <div className='MainSwiper'>
            <div className='slideBackGround' style={{opacity: showBackground ? 1 : 0 , transition : showBackground ? '5s' : '.3s'}}>
				    <ul className="circles">
                    <li style={{backgroundColor:colorOptions[index]}}></li>
                    <li style={{backgroundColor:colorOptions[index]}}></li>
                    <li style={{backgroundColor:colorOptions[index]}}></li>
                    <li style={{backgroundColor:colorOptions[index]}}></li>
                    <li style={{backgroundColor:colorOptions[index]}}></li>
                    <li style={{backgroundColor:colorOptions[index]}}></li>
                    <li style={{backgroundColor:colorOptions[index]}}></li>
                    <li style={{backgroundColor:colorOptions[index]}}></li>
                    <li style={{backgroundColor:colorOptions[index]}}></li>
                    <li style={{backgroundColor:colorOptions[index]}}></li>
            </ul>
				<div className="fog-effect"/>
            </div>
      <Swiper
        effect={"coverflow"}
        slidesPerView={0.8}
        onSlideChange={(swiperCore) => {
          rotate(swiperCore.activeIndex + 1);
          setIndex(swiperCore.activeIndex);
        }}
        spaceBetween={10}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        pagination={false}
        navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
        modules={[Autoplay, Pagination, Navigation]}
        className="swiper"
      >
        {Object.keys(swiperData).map((key, i) => (
          <SwiperSlide className="slide" key={`swiperSlide${i}`}>
            <div className="box">
                  <img
                    className="projectImage"
                    src={swiperData[key].img}
                    alt="project"
                  />
              <div className="description">
                <div className="slideTitle">
                  <div
                    className="preTitle"
                    onClick={() => {
                      window.open(
                        swiperData[key].companyLink,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    {swiperData[key].preTitle}
                  </div>
                  <div className="heading">{key}</div>
                </div>
				<div className="text">{swiperData[key].description}</div>
               
				{
					swiperData[key].projectLink ?
						<div
							className="link"
							style={{ color: swiperData[key].fontColor }}
							onClick={() => {
								window.open(
									swiperData[key].projectLink,
									"_blank",
									"noopener,noreferrer"
								);
							}}
						>
							<MainIconButton iconName={"open_in_new"} />
						</div>
						:
						null
				}
                
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="arrow-buttons">
	    <button className="arrow-left arrow">
			PREVIOUS
		</button>
		<button className="arrow-right arrow">
			NEXT
		</button>
      </div>
    </div>
  );
}
