import React, { useEffect, useRef } from 'react'
import { motion, useAnimation, useInView } from "framer-motion"

export default function Slide({className,viewMargin,children,delay,direction,value=200,fade = false,duration=1}) {
  const controls = useAnimation();
  const rootRef = useRef();
  const isInView = useInView(rootRef,{once:false,margin:viewMargin});
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
    else{
      controls.start("hidden")
    }
  }, [isInView]);
  return (
    <motion.div
      ref={rootRef}
      variants={{
        hidden: {
           opacity: fade?0:1, 
           translateY: (direction==='up')?value:(direction==='down')? -value:0, 
           translateX: (direction==='left')?value:(direction==='right')? -value:0 },
        visible: { 
          opacity: 1, 
          translateY: 0 , 
          translateX: 0 
        },
      }}
      transition={{
        type:"tween",
        duration: duration,
        delay: delay,
        
      }}
      initial="hidden"
      animate={controls}
      className={className}
    >
      {children}
    </motion.div>
  );
}
