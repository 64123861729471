import React, { useEffect, useRef, useState } from 'react'
import { motion, useAnimation, useInView } from "framer-motion"

export default function Slide({className,viewMargin,children,delay,value=200,fade = false,duration=.3}) {
  const [controls, setControls] = useState(useAnimation());
  const rootRef = useRef();
  const isInView = useInView(rootRef);
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
    else{
      controls.start("hidden")
    }
  }, [isInView]);
  return (
    <motion.div
      ref={rootRef}
      variants={{
        hidden: {
           opacity: fade?0:1, 
           height: '200px',
           translateY:100, 
        },
        visible: { 
          opacity: 1, 
          height: 'auto',
          translateY:0,
        },
      }}
      transition={{
        type:"just",
        duration: duration,
        delay: delay,
        
      }}
      initial="hidden"
      animate={controls}
      className={className}
    >
      {children}
    </motion.div>
  );
}
