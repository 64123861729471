import applicationServiceImage2 from '../images/aiys4.jpeg'
import cloudAndAiServiceImage from '../images/aibg2.png'
import integrationService3 from '../images/intergration.jpeg'
import projectImage1 from "../images/logo.png"
import projectImage2 from "../images/logo.svg"
import projectImage3 from "../images/stc.png"
import projectImage4 from "../images/takeout.png"


export const projects = {
    "AI-Powered and Cloud Solutions":{
        text:`We transform business operations by leveraging Generative AI and cloud technologies. Our AI-powered solutions automate tasks, improve decision-making, and deliver predictive insights, helping organizations save time and reduce costs. Simultaneously, our scalable and secure cloud platforms host data and applications efficiently. From cloud infrastructure setup and migration to cloud-native application development, we ensure your business gains flexibility, cost-effectiveness, enhanced collaboration, and top-tier security.`,
        image:cloudAndAiServiceImage,
        badges:["backup","memory","emoji_objects"],
        iconName: "cloud",
        contentBackgroundColor: "rgb(19,10,38,0.550)"

    },
    "Custom Application Development ":{
        text:`
        Y’s TechSolutions specializes in custom web and mobile applications for diverse industries. Our web apps are responsive, user-friendly, and scalable, ensuring top performance on any device. We create high-performance mobile apps for iOS and Android, seamlessly integrating with existing systems. From e-commerce platforms to specialized business apps, our development services deliver exceptional user experiences and drive business growth.`,
        image:applicationServiceImage2,
        badges:["adb","apple","laptop_mac"],
        iconName:"app_shortcut",
        contentBackgroundColor: "rgb(19,10,38,0.500)"
    },
    "IT Consulting and Integration Services ":{
        text:`Y’s TechSolutions offers expert IT consulting and integration services to help businesses make informed technology decisions and achieve seamless system interoperability. We provide technology strategy development, IT infrastructure assessment, and digital transformation planning to align your tech investments with business goals. Our integration services ensure smooth communication between new and existing systems, reducing redundancy and optimizing performance. We also offer comprehensive support to keep your systems secure, up-to-date, and running at peak efficiency.`,
        image:integrationService3,
        badges:["devices","headset_mic","join_right"],
        iconName: "computer",
        contentBackgroundColor: "rgb(19,10,38,0.500)"

    }
}

export const swiperData = {
    "FastTrackEHR":{
        preTitle:"Infralogics",
        companyLink : "https://infralogics.us",
        description:"In collaboration with Infralogics, FastTrack EHR is an AI-powered tool designed for healthcare professionals. It efficiently converts patient-doctor conversations into accurate health records, automates CPT and ICD-10 coding for improved billing accuracy, and provides a HIPAA-compliant platform for secure patient data management. By streamlining documentation, FastTrack EHR allows doctors to focus more on patient care, enhancing the overall healthcare experience.",
        img:projectImage1,
        bgColor:'#432483',
        fontColor:'blue',
        projectLink:'https://fasttrackehr.com'
    },
    "Minutzz":{
        preTitle:"Infralogics",
        companyLink : "https://infralogics.us",
        description:"Minutzz, developed with Infralogics, is a productivity tool for business professionals that provides instant, accurate meeting transcriptions and summaries. It highlights key points, decisions, and action items automatically, ensuring critical information is captured. With support for multiple languages, Minutzz accurately documents meetings regardless of the language spoken. It also offers secure storage and organization of meeting data in one accessible location, streamlining reviews and boosting business efficiency.",
        img:projectImage2,
        bgColor:'#189464',
        fontColor:'blue',
    },
    "SmartTimeCard ":{
        preTitle:"AEG",
        companyLink : "https://www.engineering-group.com/",
        description:"Developed with American Engineering Group, SmartTimeCard is a versatile software for streamlined time tracking and project management. It features QuickBooks integration, supervisor approvals, and automated email notifications. Accessible via web and mobile, it supports role-based access control for efficient user management. SmartTimeCard simplifies time tracking, enhances productivity, and ensures accurate accounting.",
        img:projectImage3,
        bgColor:'#a41e1d',
        fontColor:'blue',
        projectLink:'http://www.smart-time-card.com/'
    },
    "Takeouts":{
        preTitle:"Infralogics",
        companyLink : "https://infralogics.us",
        description:"Takeouts, developed with Infralogics, is a mobile app for an enhanced food ordering experience. Available on iOS and Android, it integrates with payment gateways for secure transactions, allows users to pre-order, track, and manage orders, and access order history. For restaurants, Takeouts boosts visibility with self-service ordering and real-time updates, helping them grow in a competitive market by improving customer satisfaction.",
        img:projectImage4,
        bgColor:'#fe6800',
        fontColor:'blue',
    },
}

export const homeBio = "At Y's TechSolutions, we specialize in crafting bespoke IT solutions tailored to meet the unique needs of our clients. From web and mobile applications to cutting-edge GenAI-driven solutions and cloud-based platforms, we deliver innovative technologies that drive business growth and efficiency. Partnering with industry leaders like Infralogics LLC and American Engineering Group, we provide reliable, high-performance applications that stand at the forefront of technological advancements."
export const services = 'Services'
export const titleLine2 = 'INNOVATE'
export const titleLine1 = 'INTEGRATE'
export const titleLine3 = 'ELEVATE'
export const about = "About"
export const us = "Us"
export const aboutuswhoweare = "Who we are?"
export const aboutUsWelcome = "Welcome to Our Platform"
export const welcomeText = "Y's TechSolutions is a dynamic IT services company dedicated to delivering innovative, tailor-made solutions that drive business success. Founded on the principles of excellence, innovation, and customer-centricity, we are committed to helping businesses navigate the rapidly evolving technological landscape. With a team of skilled professionals and strategic partnerships with industry leaders like Infralogics LLC and American Engineering Group, we leverage the latest technologies to create impactful solutions that address the unique challenges of our clients."
export const missions = "Our Mission"
export const mission1 = " Our mission is to empower businesses by providing high-quality, customized IT solutions that enhance productivity, streamline operations, and enable growth. We strive to be a trusted partner to our clients, offering not just technical expertise but also a deep understanding of their industries and specific needs. At Y's TechSolutions, we believe that technology should be an enabler, not a barrier, and we are dedicated to making cutting-edge technology accessible and effective for all businesses, regardless of size or sector."
export const whatWeDo = "What We Do"
export const contact = "Contact" 
export const contactDes = "Got questions? We are here to help at any time."
export const whatWeDoSummary = "At Y's TechSolutions, we specialize in developing bespoke web and mobile applications, AI-powered solutions, and cloud-based platforms. Our services are designed to address a wide range of business needs, from improving operational efficiency to enhancing customer engagement. We take a holistic approach to IT services, ensuring that every solution we deliver is integrated seamlessly into your existing systems and processes, thereby maximizing its value."
export const whatWeDo1title = "Custom Application Development"
export const whatWeDo1titleDescription = "We design and build web and mobile applications that are not only functional but also user-friendly and scalable. Whether you need a sophisticated e-commerce platform, a content management system, or a business-specific mobile app, our custom development services ensure that your applications are perfectly aligned with your business objectives."
export const whatWeDo2title = "AI-Powered and Cloud Solutions"
export const whatWeDo2titleDescription = "We harness the power of Generative AI to develop smart, automated solutions that transform business processes. Our cloud-based solutions provide secure, scalable platforms that support your business's growth and flexibility. By integrating AI and cloud technologies, we help businesses automate tasks, gain valuable insights, and operate more efficiently." 
export const whatWeDo3title = "IT Consulting and Integration"
export const whatWeDo3titleDescription = " Our consulting services guide businesses in making strategic technology decisions, from digital transformation planning to IT infrastructure assessment. We also offer integration services that ensure your new technologies work seamlessly with your existing systems, helping you maintain operational efficiency and avoid disruptions."
export const ourApproch = "Our Approach "
export const ourApprochDes = "We believe in a collaborative approach, working closely with our clients to understand their specific needs and challenges. Every project begins with a deep dive into the client’s goals, processes, and market environment, allowing us to develop solutions that are not only technologically advanced but also strategically sound. Our agile development methodology ensures that we can adapt to changing requirements and deliver solutions that provide long-term value."
export const whyChoose = "Why Choose Y's TechSolutions"
export const whyChoose1Title = "Expertise"
export const whyChoose1Desc = "Our team of developers, engineers, and consultants bring a wealth of experience and technical knowledge, ensuring that we deliver high-quality solutions that meet industry standards."
export const whyChoose2Title = "Innovation"
export const whyChoose2Desc = "We are always at the forefront of technological advancements, using the latest tools and methodologies to create solutions that are both innovative and effective."
export const whyChoose3Title = "Customer-Centric"
export const whyChoose3Desc = "We prioritize our clients’ needs, working closely with them to ensure that our solutions address their specific challenges and contribute to their success."
export const whyChoose4Title = "Reliability"
export const whyChoose4Desc = "Our commitment to quality and excellence means that we deliver reliable, secure, and scalable solutions that our clients can depend on."
export const partnership = "Our Partnerships"
export const partnershipDesc = "Our strategic partnerships with companies like Infralogics LLC and American Engineering Group enable us to expand our capabilities and offer a wider range of services. These partnerships allow us to stay ahead of industry trends and incorporate the latest technological innovations into our solutions."
export const join = "Join Us on Your Technology Journey"
export const joinDesc = "Whether you're looking to develop a new application, integrate AI into your business, or migrate to the cloud, Y's TechSolutions is here to guide you every step of the way. We are more than just a service provider—we are your technology partner, dedicated to helping you achieve your business goals through innovative and customized IT solutions."
