import React, { useEffect, useRef, useState } from 'react'
import './home.scss'
import Slide from '../../components/animations/slide/Slide'
import MainSwiper from '../../components/swiper/mainSwiper/MainSwiper'
import logoGif from '../../images/logo.gif'
import { homeBio, projects, titleLine1, titleLine2, titleLine3 } from '../../constants/data'
import Footer from '../../components/footer/Footer'
import MainHeader from '../../components/header/MainHeader'
import Grow from '../../components/animations/grow/Grow'
import { Icon } from '@mui/material'
import MainIconButton from '../../components/buttons/mainIconButton/MainIconButton'
import { useInView } from 'framer-motion'
import { Link } from 'react-scroll'


export default function Home() {
    const [serviceKey , setServiceKey] = useState(Object.keys(projects)[0])
    const [isMouseInServices, setIsMouseInServices] = useState(false)
    const rootRef = useRef();
    const isInView = useInView(rootRef, { margin: '-50% 0% -50% 0%' });

    const scrollToTop = () => {
        rootRef.current.scrollIntoView({ behavior: 'smooth' });
      };

    useEffect (() => {
        var n=0;
        const intervalId = setInterval(() => {
            if(!isMouseInServices){
                if (projects && (n !== Object.keys(projects).length - 1)) {
                    n = n + 1
                    setServiceKey(Object.keys(projects)[n])
                }
                else {
                    n = 0;
                    setServiceKey(Object.keys(projects)[0])
                }
            }
            
            }, 3000);
        
          return () => clearInterval(intervalId);
    },[isMouseInServices])

    return (
        <>
        <div className='home'>

                <MainHeader />
                <div className='go-to-top-button' style={{scale: isInView ? "0" : "1"}}>
                <Link to="header" smooth={true} duration={2500} className="navigationLink">
                    <MainIconButton title={"Scroll To Top"} onClick = {scrollToTop} fontSize={50} iconName={ "expand_less" } />
                </Link>
                </div>
                <div className='mainSection' ref={rootRef}>
                    <div className='title'>
                        <div className='text'>
                            <Slide fade={true} delay=".2">{titleLine1},</Slide>
                            <Slide fade={true} delay=".6">{titleLine2},</Slide>
                            <Slide fade={true} delay="1">{titleLine3}</Slide>
                        </div>
                    </div>
                    <img className='logoGif' src={logoGif} alt='logo' />
                    <Slide direction='up' delay=".2" fade={true} viewMargin='10% 0%  -10% 0%'>
                        <div className='description'>
                            <div className='text'>
                                {homeBio}
                            </div>

                        </div>
                    </Slide>

                </div>
                    <div className='project-section' style={{backgroundImage: `url(${projects[serviceKey].image})`}}>
                    <div className='project-section-heading'>
                    <div className='project-section-heading-text'>SERVICES</div>
                    <div className='project-box-selector'>
                        <div className='items'>
                            {Object.keys(projects).map((key, i) => (
                                <MainIconButton key={`project-selector(${i}`} className={`item ${ key === serviceKey ? "selected-item" : null}` } onClick = { () => {setServiceKey(key)}} title={key} iconName={projects[key].iconName}/>
                            ))
                            }
                        </div> 
                    </div>
                    </div>
                    
                    {
                        Object.keys(projects).map((key,i) => (
                            <div className="project" key={`project(${i})`} style={{right:key === serviceKey ? "0%" : "-100%",zIndex: key === serviceKey ? "50" : "30"}} onMouseEnter={ () => {setIsMouseInServices(true)} } onMouseLeave = { () => {setIsMouseInServices(false)} }>
                                <div className='project-title'>{key}</div>
                                <div className='project-box-container' style={{background : projects[key].contentBackgroundColor}} >
                                    <div className='project-box-heading'>
                                        <div className='title-text-background'>
                                        </div>
                                    </div>
                                    <div className='project-box-description'>
                                        <div className='text-body'>
                                            <div className='text-wrap-shape-left'>

                                            </div>
                                            <div className='text'>
                                                {projects[key].text}
                                            </div>
                                            <div className='icons'>
                                                {
                                                    projects[key].badges.map((iconName,i) => (
                                                        <Icon className='badge-icon' key={`badge (${i})`}>{iconName}</Icon>
                                                    ))
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    
                        
                    </div>
                    <div className='swiper-section'>
                        <div className='swiper-heading'> OUR PROJECTS </div>
                        <MainSwiper className="swiper" showBackground={true}/>
                    </div>
                
            <Grow>
                <Footer />
            </Grow>
        </div></>
    )
}
