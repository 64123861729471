import React, { useState } from 'react'
import './mainButton.scss'
import { colors } from '../../../constants/colors'

function MainButton ( {
    text,
    color = colors.buttonColor,
    hoverColor = colors.fontColor1,
    textColor = colors.fontColor2,
    hoverTextColor = colors.whiteColor,
    size = 15,
    filled = false,
    disabled = false,
    ...props
} )
{
    const [ isHovering, setIsHovering ] = useState( false );

    const handleMouseEnter = () =>
    {
        setIsHovering( true );
    };

    const handleMouseLeave = () =>
    {
        setIsHovering( false );
    };


    return (
        <button
            className='mainButton'
            style={
                {
                    borderColor: disabled ? colors.greyColor : color,
                    color: disabled ? colors.greyColor: ( isHovering ? 'white' : 'white'),
                    fontSize: size,
                    backgroundColor: (disabled ? 'transparent' : ( isHovering ? hoverColor : (filled ? color : 'transparent')))
                }
            }
            disabled={disabled}
            onMouseEnter={ handleMouseEnter }
            onMouseLeave={ handleMouseLeave }
            { ...props }
        >
            { text }
        </button>
    )
}

export default MainButton