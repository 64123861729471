import React from 'react'
import './mainDropdown.scss'
import Select from 'react-select'

function MainDropdown ( { title, required = false, ...props } )
{
    return (
        <div className='mainDropdown'>
            <div className='title'>{ `${ title }${ required ? '*' : '' }` }</div>
            <Select
                className="dropdown"
                classNamePrefix="select"
                { ...props }
            />
        </div>
    )
}

export default MainDropdown