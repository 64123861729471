import React from "react";
import "./privacyPolicy.scss";
import { colors } from "../../constants/colors";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import HeaderAbout from "../../components/header/HeaderAbout";

function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <div className="privacyPolicy">
      <HeaderAbout />
      <div className="titleArea">
        <div className="title">
          <span style={{ color: colors.primaryColor }}>Privacy Policy</span>
        </div>
      </div>
      <div className="mainSection">
      <div className='privacy-policy-background'>
                <ul className="circles">
                        <li ></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                </ul>
                <div className="fog-effect"/>
            </div>
        <div className="textArea">
          <div className="title">Introduction</div>
          <div className="detail">
            At Y's TechSolutions, protecting your privacy is our priority. This
            Privacy Policy explains how we collect, use, and protect your
            personal information when you use our services. By using our
            services, you agree to the terms outlined here.
          </div>
          <div className="title">Information We Collect</div>
          <div className="point">
            <span className="main">Personal Information: </span> This includes
            your name, email, contact details, and payment information.
          </div>
          <div className="point">
            <span className="main">Usage Data:</span> We collect information
            such as IP addresses and browser types to help improve our services.
          </div>
          <div className="title">How We Use Your Information</div>
          <div className="point">
            <span className="main">Service Delivery: </span> We use your
            information to provide, maintain, and improve our services.
          </div>
          <div className="point">
            <span className="main">Communication: </span> We may contact you
            with updates, responses to inquiries, and customer support.
          </div>
          <div className="point">
            <span className="main">Security: </span> Your information helps us
            protect our services from fraud and unauthorized access.
          </div>
          <div className="title">How We Share Your Information</div>
          <div className="point">
            <span className="main">Service Providers: </span> We share your
            information with trusted third parties who assist in delivering our
            services.
          </div>
          <div className="point">
            <span className="main">Legal Compliance: </span> Your information
            may be disclosed if required by law.
          </div>
          <div className="title">Your Choices</div>
          <div className="point">
            <span className="main">Access and Update:</span> You can access and
            update your personal information at any time.
          </div>
          <div className="point">
            <span className="main">Opt-Out: </span> You have the option to opt
            out of promotional communications.
          </div>
          <div className="title">Data Security</div>
          <div className="detail">
            We implement industry-standard security measures to protect your
            information, though no system is completely secure.
          </div>

          <div className="title">Data Retention</div>
          <div className="detail">
            We retain your personal information only as long as necessary for
            the purposes stated in this policy or as required by law.
          </div>
          <div className="title">Children’s Privacy</div>
          <div className="detail">
            Our services are not intended for children under 13, and we do not
            knowingly collect personal information from them.
          </div>
          <div className="title">Changes to This Policy</div>
          <div className="detail">
            We may update this policy periodically. Any changes will be posted
            with an updated effective date.
          </div>
          <div className="title">Contact Us</div>
          <div className="detail">
            If you have any questions or concerns about this Privacy Policy,{" "}
            <NavLink to={"/contact-us"} className={"navLink"}>
              Contact Us
            </NavLink>
          </div>
          <div className="detail">
            We are committed to protecting your privacy and addressing any
            concerns you may have.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
