import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import DataStoreContextProvider from "./context/DataStoreContext";
import LoaderContextProvider from "./context/LoaderContext";
import NotificationProvider from "./context/NotificationContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <LoaderContextProvider>
      <NotificationProvider>
        <DataStoreContextProvider>
          <App />
        </DataStoreContextProvider>
        </NotificationProvider>
      </LoaderContextProvider>
  </React.StrictMode>
);
